import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import Layout from 'gatsby-theme-kn/src/components/modules/Layout'
import DealerMap from 'gatsby-theme-kn/src/components/modules/DealerMap'

const ClientPage = ({ location, pageContext, path }) => {
  const [geolocation, setGeolocation] = useState(null)
  const [error, setError] = useState(null)

  const query = location.state && location.state.value
  const byInput = location.state?.byInput && location.state.byInput
  const byCoords = location.state?.byCoords && location.state.byCoords

  const { title } = pageContext

  const locale = path.split('/')[1]

  const onChange = ({ coords }) => {
    setGeolocation({
      lat: coords.latitude,
      lng: coords.longitude,
    })
  }
  const onError = (error) => {
    setError(error.message)
  }

  useEffect(() => {
    if (byCoords) {
      const geo = navigator.geolocation
      if (!geo) {
        setError('Geolocation is not supported')
        return
      }
      const watcher = geo.watchPosition(onChange, onError)
      return () => geo.clearWatch(watcher)
    }
  }, [byCoords])

  const alternates = pageContext.alternates.filter((alternate) => {
    if (alternate.full_slug.split('/')[0] !== location.pathname.split('/')[1]) {
      return alternate
    }
  })

  /*  console.log(pageContext)
  console.log(locale) */

  return (
    <Layout
      pageName="home"
      locale={locale}
      alternates={alternates}
      noDealerSearch
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div>
        <DealerMap
          searchQuery={query}
          byInput={byInput}
          byCoords={byCoords}
          coords={geolocation}
          error={error}
          title={title}
        />
      </div>
    </Layout>
  )
}

export default ClientPage
